import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../layout';
import config from '../../data/SiteConfig';
import { Container } from '@material-ui/core';
import '../css/main.scss';
import MarkdownSeo from '../components/SEO/MarkdownSEO';

export default function ServicesTemplate({ data }) {
  const serviceNode = data.markdownRemark;
  const service = serviceNode.frontmatter;
  const url = '/services';

  return (
    <Layout>
      <Container>
        <Helmet>
          <title>{`${service.title} | ${config.siteTitleShort}`}</title>
        </Helmet>
        <MarkdownSeo postNode={serviceNode} url={url} />
        <div className="legacy">
          <h1 className="title">{service.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: serviceNode.html }} />
        </div>
      </Container>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ServicesPostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
